<template>
  <nav
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <AppHeaderBrand />
      <div 
        id="navigation" 
        class="navbar-menu"
      >
        <AppHeaderStart />
        <AppHeaderEnd />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeader',
  components: {
    AppHeaderBrand: () => import('./AppHeaderBrand'),
    AppHeaderStart: () => import('./AppHeaderStart'),
    AppHeaderEnd: () => import('./AppHeaderEnd')
  },
  computed: {
    ...mapGetters('auth', ['isUser'])
  }
}
</script>

<style lang="sass" scoped>
.navbar
  border-bottom: $nav-bar-bottom-border
</style>
